<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>营销管理</template>
      <template v-slot:secondMenu>页面展示</template>
    </breadcrumb-nav>
    <el-card>
      <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
        <el-form-item label="显示场景" prop="sence">
          <el-select v-model="listQuery.sence" style="width:100%" @clear="cancelSence" clearable placeholder="现在显示场景">
            <el-option
              v-for="item in senceType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="resetForm('listQuery')">重置</el-button>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="showDialog"
          >添加
          </el-button>
        </el-form-item>
      </el-form>
      <el-table style="width: 100%" border :data="list">
        <el-table-column type="index" label="序号" width="80px" align="center">
        </el-table-column>
        <el-table-column prop="imageUrl" label="图片" width="80px">
          <template slot-scope="{ row }">
            <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称">
        </el-table-column>
        <el-table-column prop="sence" label="显示场景">
          <template slot-scope="scope">{{ scope.row.sence | senceLabel }}</template>
        </el-table-column>
        <el-table-column prop="showLocation" label="显示位置">
        </el-table-column>
        <el-table-column prop="showJump" label="跳转页面">
        </el-table-column>
        <el-table-column prop="prop" label="操作" width="200px">
          <template slot-scope="{row, $index}">
            <el-row>
              <el-button
                type="warning"
                icon="el-icon-edit"
                size="mini"
                @click="update(row)"
              >修改</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="del(row)"
              >删除</el-button
              >
            </el-row>
            <el-row v-show="listQuery.sence">
              <el-button icon="el-icon-top" style="margin-left: 0;margin-top:10px;" type="success" size="mini" @click="upindex($index,row)">上移</el-button>
              <el-button icon="el-icon-bottom" type="success" size="mini"  @click="downindex($index,row)">下移</el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5, 10]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <el-dialog
        :title="form.id ? '修改配置' : '添加配置'"
        :visible.sync="dialogFormVisible"
      >
        <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
          <el-form-item label="名称" label-width="100px" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="显示场景" label-width="100px" prop="sence">
            <el-select v-model="form.sence" style="width:100%" placeholder="请选择显示场景">
              <el-option
                v-for="item in senceType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="显示位置" label-width="100px" prop="location">
            <el-select v-model="form.location" style="width:100%" placeholder="请选择显示位置">
              <el-option
                v-for="item in pageDefineList"
                :key="item.pageAddr"
                :label="item.name"
                :value="item.pageAddr">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转位置" label-width="100px" prop="jump">
            <el-select v-model="form.jump" style="width:100%" placeholder="请选择跳转位置">
              <el-option
                v-for="item in pageDefineList"
                :key="item.pageAddr"
                :label="item.name"
                :value="item.pageAddr">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片" label-width="100px" prop="icon">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleUploadSuccess"
              :headers="imgUploadHeaders"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addOrUpdate"
          >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import {
  getPageShowList,
  delPageShowRequest,
  updatePageShowRequest,
  addPageShowRequest,
  sortPageShowRequest,
  getPageDefineList
} from '../../../network/market'

export default {
  name: 'PageShow',
  components: {
    BreadcrumbNav
  },
  filters: {
    senceLabel (value) {
      switch (value) {
        case 1:
          return '首页轮播'
        case 2:
          return '首页热门'
        default:
          return '首页轮播'
      }
    },
    locationLabel (value) {
      return '位置'
    }
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10,
        sence: ''
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      pageDefineList: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      disabled: false,
      // 收集签到规则信息
      form: {
        icon: '',
        name: '',
        sence: '',
        jump: '',
        location: ''
      },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        location: [
          { required: true, message: '请输入显示位置', trigger: 'blur' }
        ],
        sence: [
          { required: true, message: '请选择显示场景', trigger: 'change' }
        ],
        jump: [
          { required: true, message: '请输入跳转地址', trigger: 'blur' }
        ],
        icon: [{ required: true, message: '请选择图标' }]
      },
      senceType: [
        {
          label: '首页轮播',
          value: 1
        },
        {
          label: '首页热门',
          value: 2
        }
      ]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.listPageDefine()
  },
  methods: {
    cancelSence () {
      this.form.sence = ''
    },
    listPageDefine () {
      getPageDefineList({ status: 1 }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取页面定义列表失败', 'error')
        }
        this.pageDefineList = result.data.list
        this.getList()
      })
    },
    getShowPage (page) {
      const result = this.pageDefineList.find(item => {
        return item.pageAddr === page
      })
      if (result !== undefined) {
        return result.name
      } else {
        return page
      }
    },
    getList () {
      getPageShowList(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
          item.showLocation = this.getShowPage(item.location)
          item.showJump = this.getShowPage(item.jump)
        }
        this.total = result.data.total
      })
    },
    search () {
      this.getList()
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    cancelStatus () {
      this.listQuery.sence = ''
    },
    update (row) {
      this.dialogFormVisible = true
      this.form = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    del (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPageShowRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { icon: '', name: '', location: '', jump: '', sence: '' }
      this.imageUrl = ''
    },
    update2Srv () {
      updatePageShowRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改失败', 'error')
        }
        this.alertMessage('修改成功', 'success')
        this.getList()
      })
    },
    add2Srv () {
      addPageShowRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加失败', 'error')
        }
        this.alertMessage('添加成功', 'success')
        this.getList()
      })
    },
    addOrUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        if (this.form.id) {
          this.update2Srv()
        } else {
          this.add2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    handleImgRemove (file) {
      this.form.icon = ''
      this.imageUrl = ''
    },
    upindex (index, row) {
      if (index > 0) {
        const curData = this.list[index]
        const upData = this.list[index - 1]
        const sortItem = []
        sortItem.push({ id: curData.id, sortNum: upData.sortNum })
        sortItem.push({ id: upData.id, sortNum: curData.sortNum })
        sortPageShowRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.list.splice(index - 1, 1)
          this.list.splice(index, 0, upData)
        })
      } else {
        this.$message.warning('已经是第一条了！')
        return false
      }
    },
    downindex (index, row) {
      if (index + 1 === this.list.length) {
        this.$message.warning('已经是最后一条了！')
        return false
      } else {
        const downData = this.list[index + 1]
        const curData = this.list[index]
        const sortItem = []
        sortItem.push({ id: curData.id, sortNum: downData.sortNum })
        sortItem.push({ id: downData.id, sortNum: curData.sortNum })
        sortPageShowRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.list.splice(index + 1, 1)
          this.list.splice(index, 0, downData)
        })
      }
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
